/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.columna-nombre {
  color: #525f7f;
  font-size: 14px;
  font-weight: 700;
}
.agentes-table {
  background: transparent !important;
}

.columnas {
  color: black;
  // color: #344675;
  font-size: 14px;
  font-weight: 400;
}

.cabecera {
  // color: rgba(255, 255, 255, 0.7);
  color: #344675;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.agentes-table-pagination {
  background: transparent !important;
  // color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}

.mat-select-value-text.ng-star-inserted {
  // color: white;
}

/* .boton-add {
  background-color: #fc5b8e !important;
  color: white !important;
}
.boton-add:hover,
.boton-add:focus,
.boton-add:active {
  background-color: red !important;
} */

.estado-verde {
  background: green;
}
.estado-rojo {
  background: red;
}
.estado-amarillo {
  background: #bfbf47;
}
